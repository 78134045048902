import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Ads({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">SentriX ADS</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing max-width">
                        <div className="_typing-title center title-margin first" data-aos="fade-up" data-aos-duration="500">
                            보안사고의 예방, SentriX ADS가 책임집니다
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            SentriX ADS(Anomaly Detection System)는 오랜 시간 국내 주요 기업과 기관의 보안을 책임진 가이온이 그 경험과<br />
                            노하우를 기반으로 다양한 시나리오를 가지고 인공지능 머신러닝 기술을 접목시켜 각종 보안사고를<br />
                            예방하는 시스템입니다.
                        </div>
                        <div className="_typing-title center title-margin" data-aos="fade-up" data-aos-duration="500">
                            세밀한 시나리오들을 바탕으로 이상징후를 사전에 파악
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            날이 갈수록 고도화되는 보안 위협을 가장 효과적으로 막는 방법 중 하나가 바로 시나리오 기반 예방 시스템 운영입니다.<br />
                            다양한 보안 사고 케이스 정보를 바탕으로 작성된 세밀한 시나리오가 가이온 SentriX-ADS가 가지고 있는 강점입니다.
                        </div>
                        <div className="_typing-title center title-margin" data-aos="fade-up" data-aos-duration="500">
                            Rule-Based와 ML(Machine Learning)-Based를 조화시킨 이상 행위 탐지
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            정확하고 예방적인 사용자들의 이상 행위 탐지를 위해서는 시나리오를 기반한 Rule-Based 기반 접근 뿐 아니라 꾸준한<br />
                            기계학습(Machine Learning)으로 지속적인 업그레이드가 필요합니다. SentriX-ADS는 이 둘의 강점이 가장<br />
                            잘 나타나는 이상치 탐지 시스템입니다.
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/eson_ads_01.png" />
                            </div>
                        </div>
                    </div>
                    <div className="Link">
                        문의처 : <span>sales@gaion.kr</span> (AI솔루션부문, 영업팀)<br />
                        Tel :  <span>02.6956.5942</span>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default Ads; 