import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Soc({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">SentriX</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing max-width">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
                            Security control operation, view at a glance and manage at once with SentriX
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            SentriX visualizes various security-related incidents and related data at a glance,
                            A system that manages and improves security posture.
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/soc_01.png" />
                            </div>
                        </div>
                    </div>
                    <div className="typing max-width">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="1000">
                            A security portal that provides security logs, security data, and threat information from check to response
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            All security-related information (logs, data, threat information) is individually checked, and security practitioners judge and respond to incidents<br />
                            Handle all cumbersome tasks at once. Processing and monitoring from the occurrence of incidents<br />
                            SentriX is responsible for reporting.
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/soc_02.png" />
                            </div>
                        </div>
                    </div>
                    <div className="Link">
                        Contact : <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel :  <span>02.6956.5942</span>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default Soc; 