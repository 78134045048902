import AOS from "aos";
import Ads from "./ads";
import Soc from "./soc";
import Mes from "./mes";
import Cato from "./cato";
import "aos/dist/aos.css";
import Splunk from "./splunk";
import Teradata from "./teradata";
import Tab from "view/parts/tab/tab";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "view/pages/error/notfound";

function Index() {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            <Tab currentIndex={2} />
            <Routes>
                <Route path="/splunk" element={<Splunk />} />
                <Route path="/ads" element={<Ads />} />
                <Route path="/eson" element={<Ads />} />
                <Route path="/sentrix" element={<Soc />} />
                <Route path="/teradata" element={<Teradata />} />
                <Route path="/mes" element={<Mes />} />
                <Route path="/cato" element={<Cato />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </React.Fragment>
    );
}

export default Index;