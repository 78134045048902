import React, { useState, useCallback, useEffect, useRef } from "react";

const { kakao } = window;

function Map1() {
    useEffect(() => {
        mapscript();
    }, []);

    const mapscript = () => {
        let container = document.getElementById("map");
        let options = {
            center: new kakao.maps.LatLng(37.503206, 127.0465684),
            level: 2,
        };
        //map
        const map = new kakao.maps.Map(container, options);

        //마커가 표시 될 위치
        let markerPosition = new kakao.maps.LatLng(
            37.503206,
            127.0465684
        );

        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });

        // 마커를 지도 위에 표시
        marker.setMap(map);
    };
    return (
        <div
            style={{
                width: '100%',
                display: 'inline-block',
            }}
        >
            <div id="map" style={{ width: '100rea%', height: '500px' }}></div>
            <div className="mapAddress">
                ABD(Ai - 빅데이터센터) : 서울시 강남구 테헤란로 322 한신인터밸리 11층
            </div>
        </div>
    );
}

export default Map1;