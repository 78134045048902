import "view/style/css/footer.css";
import classNames from 'classnames';
import logo from 'view/images/main_logo.png'
import { useState, useEffect } from 'react';
import { Modal } from 'view/parts/modal/modal';
import { Privacy } from 'view/parts/main/privacy';
import { menuItems } from 'view/parts/tab/tabList';
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { localeInfo } from "view/utils";
import footerItems from "./footerItems.json";

export function Footer(props) {
    const { MainModal } = props;
    const location = useLocation();
    const locale = useRecoilValue(localeInfo);
    const items = footerItems[locale]
    const [open, setOpen] = useState(false);
    const [isMain, setMain] = useState(false);

    const handleChange = (e) => {
        if (e.target.value != "") {
            window.open(e.target.value, '_blank');
        }
    }

    useEffect(() => {
        if (location.pathname === "/" || location.pathname == "/en/") {
            setMain(true);
        }
        return () => {
            setMain(false);
        };
    }, [location.pathname]);

    return (
        <div className={classNames("footer_wrap", { deactivate: location.pathname === "/" || location.pathname === "/en/" })}>
            <div className="wrap">
                <div className="pages_wrap">
                    <Link to={"/"}>
                        <img className="logo_wrap" src={logo} alt="logo" />
                    </Link>
                    <ul>
                        {menuItems(locale).map((element, index) => (
                            <li key={index}><Link className='footer_title' to={element.link}>{element.title}</Link></li>
                        ))}
                    </ul>
                    <select onChange={(e) => handleChange(e)} className="fam_select">
                        <option value="">{items.family}</option>
                        <option value="https://we-trade.co.kr/">WeTrade</option>
                        <option value="https://www.wetrademall.com/">WeTrade Mall</option>
                    </select>
                </div>
                <div className="info_wrap">
                    <p>{items.companyName} ㅣ {items.companyNumber} ㅣ {items.companyCeo} ㅣ
                        <a onClick={() => {
                            setOpen(true)
                            MainModal(true)
                        }}>{items.privacy}</a>
                    </p>
                    <p>Tel. 02-2051-9595 ㅣ Fax. 02-2051-9596 ㅣ Email.
                        <a href="mailto:marketing@gaion.kr" style={{ textDecoration: 'none', color: '#777777' }}>
                            marketing@gaion.kr
                        </a>
                        ㅣ {items.abc}
                    </p>
                    <p>{items.tcc} ㅣ {items.factory}</p>
                    <p>Copyright 2023. GAION CO.,LTD. All rights Reserved.</p>
                </div>
            </div>
            {isMain == false && (
                <Modal className={classNames("modal_wrap", { on: open })} title={"개인정보 처리방침"} open={open} width={1400} setOpen={setOpen} body={<Privacy />} />
            )}
        </div>
    );
}