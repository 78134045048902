import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

/*swiper*/
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
/*swiper*/

function Mes({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">gSmart MES</div>
                        </div>
                    </div>
                    <Nav />

                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart MES는 제조현장의 주문 접수부터 완제품의 출고까지 전 활동을 관리하는<br />
                            시스템 이며, 제조 현장의 각종 정보 (생산, 품질, 입/출고, 재고관리 등)을 PC나 휴대용<br />
                            단말기(스캐너, PDA, Tablet, 스마트폰 등)을 통해 디지털 관리를 구현합니다.
                        </div>

                        <div className="_typing-text center">
                            생산 설비 및 다양한 IoT 센서를 통해 실시간으로 제조 공정 및 설비등의 Data를 수집-집계-분석-모니터링 등 제반 활동을 지원하고,<br />
                            설비 및 센서 데이터와 MES 데이터를 연계 분석하여 장애탐지-예측 기능 및 공정 프로세스 최적화 기능을 제공합니다.
                        </div>
                    </div>
                </div>
                <div className="grayBox">
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/mes_01.png" />
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Why gSmart MES?</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            gSmart MES는 공정 과정에서 설비, 작업자, 작업 등 시시각각 발생하는 생산정보를 실시간<br />데이터를 수집하고,
                            처리하여 현장관리자에게 제공(모니터링)하는 POP시스템을 구축하며,<br />
                            생산 제품에 관한 생산실적 집계 및 분석을 통하여 품질향상을 지원하고 생산현장에서 발생 <br />
                            할 수 있는 모든 정보 통합 관리 합니다.
                        </div>
                    </div>
                    <div className="grid vision">
                        <div className="item even"><img src="/images/vision_icon_01.png" /></div>
                        <div className="item odd">
                            센서 데이터 분석
                            <div className="content">센서 데이터 분석으로 장애탐지 및 예측</div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_07.png" /></div>
                        <div className="item odd">
                            Visibility 제공
                            <div className="content">전 생산공정에 대한 Visibility </div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_04.png" /></div>
                        <div className="item odd">
                            공정 최적화
                            <div className="content">Process Mining을 통한 공정 최적화</div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_08.png" /></div>
                        <div className="item odd">
                            실시간 모니터링
                            <div className="content">공장내 Facility의 실시간 운영 상태 모니터링</div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_09.png" /></div>
                        <div className="item odd">
                            텍스트 데이터와 통합 분석
                        </div>
                        <div className="item even"><img src="/images/vision_icon_10.png" /></div>
                        <div className="item odd">
                            MES 연계 분석
                        </div>
                    </div>
                    <div className="introBox solutionSwiper" style={{ paddingTop: "40px" }}>
                        <div className="imgSwiper">
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={1}
                                speed={1000}
                                loop={false}
                                modules={[Pagination]}
                                pagination={{
                                    type: "progressbar",
                                    clickable: true,
                                    el: '.mobile-rolling01',
                                }}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 2,  //브라우저가 768보다 클 때
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 2,  //브라우저가 1024보다 클 때
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <img src="/images/mes_02.png" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="/images/mes_03.png" />
                                </SwiperSlide>
                            </Swiper>
                            < div className="swiper-pagination mobile-rolling01" />
                        </div>
                    </div>
                    <div className="darkGrid four">
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                기준정보관리
                            </div>
                            <div className="dark-content btnArea">
                                <button>기준정보</button>
                                <ul>
                                    <li>기초코드 정보 등록</li>
                                    <li>품목정보 등록</li>
                                    <li>BOM 정보 등록</li>
                                    <li>거래처 정보 등록</li>
                                    <li>사용자 정보 등록</li>
                                    <li>사용자 권한정보 수정 및 삭제</li>
                                    <li>HACCP 양식 등록</li>
                                    <li>로그 이력 조회</li>
                                    <li>창고정보</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                주문관리
                            </div>
                            <div className="dark-content btnArea">
                                <button>주문정보</button>
                                <ul>
                                    <li>주문정보 등록</li>
                                    <li>주문진행현황 조회</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                재고관리
                            </div>
                            <div className="dark-content btnArea">
                                <button>재고정보</button>
                                <ul>
                                    <li>입고 등록</li>
                                    <li>재고정보 조회</li>
                                    <li>재고정보 조정</li>
                                    <li>불량재고 조회</li>
                                    <li>재고이동정보</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                모니터링
                            </div>
                            <div className="dark-content btnArea">
                                <button>모니터링</button>
                                <ul>
                                    <li>생산현장 POP</li>
                                    <li>지시대비 생산실적</li>
                                    <li>지시대비 출고실적</li>
                                    <li>HACCP 등록현황</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                설비관리
                            </div>
                            <div className="dark-content btnArea">
                                <button>설비정보</button>
                                <ul>
                                    <li>부품정보 관리</li>
                                    <li>설비정보 관리</li>
                                    <li>설비가동현황 조회</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                생산관리
                            </div>
                            <div className="dark-content btnArea">
                                <button>생산정보</button>
                                <ul>
                                    <li>생산지시 등록</li>
                                    <li>생산지시 정보 조회</li>
                                    <li>생산실적 정보</li>
                                    <li>생산실적 POP</li>
                                    <li>기간별 생산량 조회</li>
                                </ul>
                            </div>
                        </div >
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                출고관리
                            </div>
                            <div className="dark-content btnArea">
                                <button>출고정보</button>
                                <ul>
                                    <li>출고지시 등록</li>
                                    <li>출고지시 정보 조회</li>
                                    <li>출고실적 등록</li>
                                    <li>제품별 출고정보 조회</li>
                                </ul>
                            </div>
                        </div >
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Application
                            </div>
                            <div className="dark-content btnArea">
                                <button>모바일</button>
                                <ul>
                                    <li>입고/출고 등록</li>
                                    <li>재고정보 조회</li>
                                    <li>재고정보 조정</li>
                                    <li>생산실적 등록</li>
                                    <li>HACCP 등록</li>
                                    <li>창고정보</li>
                                </ul>
                            </div>
                        </div >
                    </div >
                </div >
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart MES 특징</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            전공정에 이르는 생산활동을 최적화 할 수 있는 정보를 제공하며 생산 계획부터 작업지시, 생산<br />
                            실적 등록 등을 통해 생산 이력 관리가 가능하며 생산업무의 자동화와 공정 진행정보, 설비 모니<br />
                            터링 및 Control, 품질정보 Tracking, 실적정보 집계, 창고 운영관리, 원부재료 및 재고 관리<br />
                            등 생산현장에서 발생하는 현장정보의 수집 및 분석을 통한 데이터 처리로 예측데이터에 활용
                        </div>
                    </div>
                    <ul className="mesGrid" data-aos="fade-up" data-aos-duration="500">
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_01.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">스마트 기기에 최적화된 MES</div>
                                <div className="_mesContent">웹기반으로 PC, 스마트폰 등 멀티 디바이스 접속 환경 제공</div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_02.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">Paperless 실현</div>
                                <div className="_mesContent">전자 문서 및 전자 결재를 통한 현장 오염 및 문서
                                    분실 위험 감소</div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_03.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">생산현장 데이터 분석</div>
                                <div className="_mesContent">
                                    작업지시, 생산실적, 설비관리등 생산현장의<br />
                                    데이터 실시간 집계 및 분석
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_04.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">업무 표준화</div>
                                <div className="_mesContent">시스템 기반으로 업무 표준화 및 생산성향상 실현</div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_05.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">모니터링 시스템</div>
                                <div className="_mesContent">
                                    생산진행 모니터링시스템을 통해 생산정보를<br />
                                    전사적 으로 공유
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_06.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">기업 맞춤 MES</div>
                                <div className="_mesContent">
                                    제조 현장에 맞는 맞춤 기능제공 제조 업무 모듈단위로<br />
                                    세분화된 기능구현
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">gSmart MES 기대효과</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            POP 시스템을 통한 모니터링으로 작업 환경 개선/설비 보전시간 예측/생산계획 및 실적<br />
                            모니터링/일관성 있는 정보 공유체계로 현장과 사무실 간 원활한 의사소통으로 경영 환경 개선 기대
                        </div>
                    </div>
                    <div className="mesImg">
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_04.png" />
                            </div>
                        </div>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_05.png" />
                            </div>
                        </div>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_06.png" />
                            </div>
                        </div>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_07.png" />
                            </div>
                        </div>
                    </div>
                    <div className="Link">
                        문의처 : <span>sales@gaion.kr</span> (AI솔루션부문, 영업팀)<br />
                        Tel :  <span>02.6956.5942</span>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default Mes;