import "view/style/css/header.css";
import classNames from "classnames";
import { localeInfo } from "view/utils";
import logo from 'view/images/logo@2x.png'
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { frame, menuItems } from 'view/parts/tab/tabList';
import { Link, useLocation, useNavigate } from "react-router-dom";

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const locale = useRecoilValue(localeInfo);
    const [isHover, setIsHover] = useState(false);
    const [showGnb, setShowGnb] = useState(false);
    const [width, setWidth] = useState(window.outerWidth);
    const [showSiteMap, setShowSiteMap] = useState(false);
    const [localeState, setLocaleState] = useRecoilState(localeInfo);

    const handleOnClickLink = link => {
        setShowGnb(false);
        setShowSiteMap(false);
        setIsHover(false);
        link && navigate(link);
    }

    const handleLang = (e) => {
        const lang = e.target.value;
        const pathWithoutLang = location.pathname.replace("/en", "")
        setLocaleState(lang)
        if (lang === "kr") {
            window.location.href = `${pathWithoutLang}`;
        } else if (lang !== "" || lang !== undefined) {
            window.location.href = `/${lang}${pathWithoutLang}`;
        }
    }

    useEffect(() => {
        setWidth(window.outerWidth);
    }, [window.outerWidth])

    return (
        <div className="header_wrap">
            <div className={classNames("header", {
                expand: width <= 1000 && isHover,
                hover: width >= 1000 && isHover
            })}
                style={{ overflow: showSiteMap ? "unset" : "hidden" }}
                onMouseEnter={() => (
                    setShowGnb(true),
                    width >= 1000 && setIsHover(true)
                )}
                onMouseLeave={() => (
                    setShowGnb(false),
                    width >= 1000 && setIsHover(false)
                )
                }
            >
                <div className="header_contents_wrap notosanscjkkr">
                    <Link to={"/"} onClick={() => handleOnClickLink()}>
                        <img className="logo_wrap" src={logo} alt="logo" />
                    </Link>
                    <div className="menu_wrap" style={{ gridTemplateColumns: `${frame.repeat(menuItems(locale).length)}` }}>
                        {menuItems(locale).map((element, index) => (
                            <article key={index}>
                                <span onClick={() => handleOnClickLink(element.link)}>{element.title}</span>
                                <div className={classNames("gnb", { shown: showGnb })}>
                                    <ul>
                                        {element.list.map((element, index) => (
                                            <li key={index}>
                                                <Link to={element.link}
                                                    state={{ index: index, prev: location?.state?.index }}
                                                    onClick={() => handleOnClickLink()}
                                                >
                                                    {element.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </article>
                        ))}
                    </div>
                    <div className="right_content_wrap">
                        <Link className="contact_btn" to={"company/contact"} state={{ index: 1, prev: location?.state?.index }}
                            onClick={() => handleOnClickLink()}>
                            <span className="btn_text">제품 문의하기</span>
                        </Link>
                        <select
                            defaultValue={locale}
                            onChange={handleLang}>
                            <option value="kr">Kor</option>
                            <option value="en">Eng</option>
                        </select>
                        <div className="lines_wrap" onClick={() => { width >= 1000 ? setShowSiteMap(!showSiteMap) : setIsHover(!isHover) }}>
                            {Array.from({ length: 3 }, () => undefined).map((_e, i) => (
                                <div key={i} className="rectangle" />
                            ))}
                        </div>
                    </div>
                    <div className={classNames("responsive_gnb", { open: width >= 1000 && showSiteMap })}>
                        <div className="notosanscjkkr">
                            {menuItems(locale).map((element, index) => (
                                <li key={index}>
                                    <div className="gnb_page_title">
                                        <h2 style={{ margin: "0 0 10px 0" }}><Link to={element.link} onClick={() => handleOnClickLink()}>{element.title}</Link></h2>
                                    </div>
                                    <ul className="gnb_page_list">
                                        {element.list.map((e, i) => (
                                            <li key={i} className="gnb_page_link">
                                                <Link to={e.link}
                                                    state={{ index: i, prev: location?.state?.index }}
                                                    onClick={() => handleOnClickLink()}
                                                >{e.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>

                                </li>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames("site_map_container", { open: showSiteMap })}>
                <div className="site_map_content notosanscjkkr">
                    <section className="content_area">
                        <div className="site_map_title_wrap">
                            <h1 className="site_map_title">SITEMAP</h1>
                            <button className="close_btn" onClick={() => setShowSiteMap(false)}><p className="xi-close-thin" /></button>
                        </div>
                        <article className="site_map_list">
                            <ul className="pages_list">
                                {menuItems(locale).map((element, index) => (
                                    <li key={index}>
                                        <div style={{ overflow: 'hidden', textAlign: 'center' }}>
                                            <Link className="major_title" onClick={() => handleOnClickLink()} to={element.link}>{element.title}</Link>
                                        </div>
                                        <ul>
                                            {element.list.map((e, i) => (
                                                <li key={i} className="page_link">
                                                    <Link to={e.link}
                                                        className="page_title"
                                                        state={{ index: i, prev: location?.state?.index }}
                                                        onClick={() => handleOnClickLink()}
                                                    >{e.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </article>
                    </section>
                </div>
            </div>
        </div >
    );
}

export default Header;