import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

/*swiper*/
import "swiper/css";
import "swiper/css/effect-fade";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
/*swiper*/

function Mes() {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">gSmart MES</div>
                        </div>
                    </div>
                    <Nav />

                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="_typing-title center">
                            gSmart MES manages all activities from receiving orders at the manufacturing site to<br />
                            shipping finished products. It is a system, and various information (production, quality,<br />
                            warehousing/shipping, inventory management, etc.) of the manufacturing site is stored on PC or portable<br />
                            Implement digital management through terminals (scanners, PDAs, tablets, smartphones, etc.).
                        </div>
                        <div className="_typing-text center">
                            Supports various activities such as collecting, aggregating, analyzing, and monitoring data of manufacturing processes and facilities in real time through<br />
                            production facilities and various IoT sensors, Facility and sensor data and MES data are linked and analyzed to provide failure detection-prediction functions and<br />
                            process optimization functions.
                        </div>
                    </div>
                </div>
                <div className="grayBox">
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                            <img src="/images/mes_01.png" />
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Why gSmart MES?</div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            gSmart MES provides real-time production information generated in the process,<br />
                            such as facilities, workers, and tasks. Establish a POP system that collects, processes,<br />
                            and provides (monitors) data to field managers, Supports quality improvement through aggregation<br />
                            and analysis of production performance on production products, and occurs at the production site<br />
                            Integrated management of all possible information.
                        </div>
                    </div>
                    <div className="grid vision">
                        <div className="item even"><img src="/images/vision_icon_01.png" /></div>
                        <div className="item odd">
                            Sensor data analysis
                            <div className="content">
                                Failure detection and prediction through<br />
                                sensor data analysis
                            </div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_07.png" /></div>
                        <div className="item odd">
                            Provide visibility
                            <div className="content">Visibility for the entire production process</div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_04.png" /></div>
                        <div className="item odd">
                            Process Optimization
                            <div className="content">Process Optimization through Process Mining</div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_08.png" /></div>
                        <div className="item odd">
                            Live monitoring
                            <div className="content">
                                Real-time operational status monitoring of<br />
                                facilities in the factory
                            </div>
                        </div>
                        <div className="item even"><img src="/images/vision_icon_09.png" /></div>
                        <div className="item odd">
                            Integrated analysis with text data
                        </div>
                        <div className="item even"><img src="/images/vision_icon_10.png" /></div>
                        <div className="item odd">
                            MES linkage analysis
                        </div>
                    </div>
                    <div className="introBox solutionSwiper" style={{ paddingTop: "40px" }}>
                        <div className="imgSwiper">
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={1}
                                speed={1000}
                                loop={false}
                                modules={[Pagination]}
                                pagination={{
                                    type: "progressbar",
                                    clickable: true,
                                    el: '.mobile-rolling01',
                                }}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 2,  //브라우저가 768보다 클 때
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 2,  //브라우저가 1024보다 클 때
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <img src="/images/mes_02.png" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="/images/mes_03.png" />
                                </SwiperSlide>
                            </Swiper>
                            < div className="swiper-pagination mobile-rolling01" />
                        </div>
                    </div>
                    <div className="darkGrid four">
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Standard information management
                            </div>
                            <div className="dark-content btnArea">
                                <button>Standard information</button>
                                <ul>
                                    <li>Register basic code information</li>
                                    <li>Register item information</li>
                                    <li>Register BOM information</li>
                                    <li>Register customer information</li>
                                    <li>Register user information</li>
                                    <li>Edit and delete user credentials</li>
                                    <li>Register HACCP form</li>
                                    <li>Search log history</li>
                                    <li>Warehouse information</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Order management
                            </div>
                            <div className="dark-content btnArea">
                                <button>Order Information</button>
                                <ul>
                                    <li>Register order information</li>
                                    <li>Search order progress</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Inventory Management
                            </div>
                            <div className="dark-content btnArea">
                                <button>Availability</button>
                                <ul>
                                    <li>Receipt registration</li>
                                    <li>Search inventory information</li>
                                    <li>Adjust inventory information</li>
                                    <li>Check defective inventory</li>
                                    <li>Stock transfer information</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Monitoring
                            </div>
                            <div className="dark-content btnArea">
                                <button>Monitoring</button>
                                <ul>
                                    <li>Production site POP</li>
                                    <li>Production performance against orders</li>
                                    <li>Shipping performance against orders</li>
                                    <li>HACCP registration status</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Facility management
                            </div>
                            <div className="dark-content btnArea">
                                <button>Equipment information</button>
                                <ul>
                                    <li>Part information management</li>
                                    <li>Manage equipment information</li>
                                    <li>Query facility operation status</li>
                                </ul>
                            </div>
                        </div>
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Production Management
                            </div>
                            <div className="dark-content btnArea">
                                <button>Production information</button>
                                <ul>
                                    <li>Register production order</li>
                                    <li>Search production order information</li>
                                    <li>Production performance information</li>
                                    <li>Production Performance POP</li>
                                    <li>Query production by period</li>
                                </ul>
                            </div>
                        </div >
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Release management
                            </div>
                            <div className="dark-content btnArea">
                                <button>Shipment information</button>
                                <ul>
                                    <li>Register delivery order</li>
                                    <li>Search shipping order information</li>
                                    <li>Registration of release results</li>
                                    <li>Search shipping information by product</li>
                                </ul>
                            </div>
                        </div >
                        <div className="darkBlue" data-aos="fade-up" data-aos-duration="500">
                            <div className="dark-top _Blue">
                                Application
                            </div>
                            <div className="dark-content btnArea">
                                <button>Mobile</button>
                                <ul>
                                    <li>Receipt/shipment registration</li>
                                    <li>Search inventory information</li>
                                    <li>Adjust inventory information</li>
                                    <li>Registration of production performance</li>
                                    <li>HACCP Registration</li>
                                    <li>Warehouse information</li>
                                </ul>
                            </div>
                        </div >
                    </div >
                </div >
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                Features of gSmart MES
                            </div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            It provides information that can optimize production activities ranging from production planning<br />
                            to work instructions, production Production history management is possible through performance<br />
                            registration, etc., and automation of production tasks, process progress information, and facility<br />
                            monitoring Testing and control, quality information tracking, performance information aggregation,<br />
                            warehouse operation management, raw material and inventory management Data processing through<br />
                            collection and analysis of on-site information generated at production sites, etc., used for predictive data
                        </div>
                    </div>
                    <ul className="mesGrid" data-aos="fade-up" data-aos-duration="500">
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_01.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">
                                    MES optimized for smart devices
                                </div>
                                <div className="_mesContent">
                                    Web-based multi-device access environment<br />
                                    such as PC and smartphone
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_02.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">
                                    Paperless realization
                                </div>
                                <div className="_mesContent">
                                    Reducing the risk of on-site contamination and document
                                    loss through electronic documents and electronic payment
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_03.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">
                                    Production site data analysis
                                </div>
                                <div className="_mesContent">
                                    Work instructions, production performance, facility management, etc.
                                    Data real-time aggregation and analysis
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_04.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">
                                    Work standardization
                                </div>
                                <div className="_mesContent">
                                    Work standardization and productivity improvement realization based on system
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_05.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">
                                    Monitoring system
                                </div>
                                <div className="_mesContent">
                                    Production information through the production progress monitoring system
                                    shared across the enterprise
                                </div>
                            </div>
                        </li>
                        <li className="_mesGrid" data-aos-duration="500">
                            <div className="_mesItem">
                                <img src="/images/mes_icon_06.png" />
                            </div>
                            <div className="_mesTxt">
                                <div className="_mesTitle">Enterprise Custom MES</div>
                                <div className="_mesContent">
                                    Provide customized functions for the manufacturing site Manufacturing work module unit
                                    Implementation of segmented functions
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">
                                Expected effects of gSmart MES
                            </div>
                        </div>
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="800">
                            Work environment improvement/facility maintenance time prediction/production plan and<br />
                            performance by monitoring through POP system Expected to improve business environment through<br />
                            smooth communication between field and office through monitoring/consistent information<br />
                            sharing system
                        </div>
                    </div>
                    <div className="mesImg">
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_04.png" />
                            </div>
                        </div>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_05.png" />
                            </div>
                        </div>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_06.png" />
                            </div>
                        </div>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="500">
                                <img src="/images/mes_07.png" />
                            </div>
                        </div>
                    </div>
                    <div className="Link">
                        Inquiries : <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel :  <span>02.6956.5942</span>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default Mes;