import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

function Ads({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide solution en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">SentriX ADS</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing max-width">
                        <div className="_typing-title center title-margin first" data-aos="fade-up" data-aos-duration="500">
                            SentriX ADS is responsible for the prevention of security incidents.
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            SentriX ADS (Anomaly Detection System) is a long-standing security experience for major domestic companies and institutions.<br />
                            Various security accidents by incorporating artificial intelligence machine learning technology with various scenarios based on know-how.<br />
                            It's a preventive system.
                        </div>
                        <div className="_typing-title center title-margin" data-aos="fade-up" data-aos-duration="500">
                            Identify anomalies in advance based on detailed scenarios
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            One of the most effective ways to prevent escalating security threats is to operate a scenario-based prevention system.<br />
                            The advantages of Gaion SentriX-ADS are detailed scenarios based on various security incident case information.
                        </div>
                        <div className="_typing-title center title-margin" data-aos="fade-up" data-aos-duration="500">
                            nomaly detection that harmonizes Rule-Based and Machine Learning (ML)-Based
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            For accurate and preventive user anomaly detection, consistent <br /> as well as scenario-based rule-based approaches<br />
                            Continuous upgrades to Machine Learning are required. SentriX-ADS has the most advantages of these two.<br />
                            A well-visible anomaly detection system.
                        </div>
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage">
                                <img src="/images/eson_ads_01.png" />
                            </div>
                        </div>
                    </div>
                    <div className="Link">
                        Contact: <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel : <span>02.6956.5942</span>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default Ads; 