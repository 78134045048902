import React from "react";
import "view/style/css/solution.css";
import Nav from "view/parts/nav/nav";

const Soc = () => {
    return (
        <React.Fragment>
            <div className="content-inseide solution">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle">
                            <div data-aos="fade-up" data-aos-duration="500">SentriX</div>
                        </div>
                    </div>
                    <Nav />
                    <div className="typing max-width">
                        <div className="_typing-title  center" data-aos="fade-up" data-aos-duration="1000">
                            보안 관제 운영, SentriX로 한 눈에 보고, 한 번에 관리 하세요
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            <i style={{fontSize: 20, fontWeight: 500}}>SentriX</i> 는 머신러닝을 기반으로 지능형 지속 공격(APT)과 내부 이상징후를 탐지(ADS)하고 확장형 데이터 연계 시스템(EDC) 등을 통해<br />
                            기존 운영 중인 빅데이터 로그(SIEM)와 연계하며 실무 프로세스에 최적화된 보안 운영포탈(SOP)로 기업의 보안관제 수준을 향상시킬 수 있습니다.
                        </div>
                    </div>
                    <div className="introBox" style={{ padding: "50px 0" }} data-aos="fade-up" data-aos-duration="1000">
                        <div className="imgScroll">
                            <div className="wrap esonImage" style={{ height: 630, borderRadius: 30, overflow: "hidden" }}>
                                <img src="/images/sentrix_01.png" />
                            </div>
                        </div>
                    </div>
                    <div className="typing max-width">
                        <div className="_typing-title  center" data-aos="fade-up" data-aos-duration="1000">
                            다양한 솔루션들을 연계하여 하나로 통합된 환경을 구축, 새로운 가치와 인사이트를 창출하세요
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            기존 구축된 플랫폼과 시스템을 연계하고 상용 제품 뿐만 아니라 오픈소스 데이터도 활용이 가능합니다.<br />
                            보안 담당자는 다양한 데이터 소스와 로그로부터 위협을 발굴해 내고 침해 대응 프로세스를 유연하게 처리할 수 있습니다.
                        </div>
                    </div>
                    <div className="introBox" style={{ padding: "50px 0" }} data-aos="fade-up" data-aos-duration="1000">
                        <div className="imgScroll">
                            <div className="wrap esonImage" style={{ height: 630, borderRadius: 30, overflow: "hidden" }}>
                                <img src="/images/sentrix_02.png" />
                            </div>
                        </div>
                    </div>
                    <div className="typing max-width">
                        <div className="_typing-title  center" data-aos="fade-up" data-aos-duration="1000">
                            SentriX 만의 특화된 절차와 도구들로 보안 관제를 고도화하세요
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            효율적인 보안관제 티켓 처리, 보안 관제의 지식 정보와 최적화된 도구 제공을 통해 새로운 관제 프로세스를 경험하세요.
                        </div>
                    </div>
                    <div className="introBox" style={{ padding: "50px 0" }} data-aos="fade-up" data-aos-duration="1000">
                        <div className="imgScroll">
                            <div className="wrap esonImage" style={{ height: 630, borderRadius: 30, overflow: "hidden" }}>
                                <img src="/images/sentrix_03.png" />
                            </div>
                        </div>
                    </div>
                    <div className="Link">
                        문의처 : <span>sales@gaion.kr</span> (AI솔루션부문, 영업팀)<br />
                        Tel :  <span>02.6956.5942</span>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
}

export default Soc; 