import React from "react";
import "view/style/css/intro.css";
import "view/style/css/splunk.css";
import Nav from "view/parts/nav/nav";

function Splunk({ props }) {
    return (
        <React.Fragment>
            <div className="content-inseide splunk en">
                <div className="wrap">
                    <div className="contentArea">
                        <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Splunk</div>
                    </div>
                    <Nav />
                    {/* section 01 */}
                    <div className="typing">
                        <div className="_typing-title center" data-aos="fade-up" data-aos-duration="500">
                            <div className="big">From machine data analysis to security</div>
                            Splunk has the answer.
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Splunk, a real-time distributed platform that can collect, store, analyze, and visualize machine data without any restrictions
                        </div>

                        <div className="introBox">
                            <div className="imgScroll">
                                <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                    <img src="/images/splunk_01.png" />
                                </div>
                            </div>
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            From machine data analysis to security, Splunk has the answer. Collect, save, analyze machine data without any restrictions,<br />
                            Splunk, a real-time distributed platform that can visualize. Splunk captures machine data from any source<br />
                            The most advanced operational intelligence platform that collects and analyzes in real time.
                            <br /><br />
                            We collect structured and unstructured data from all your equipment, servers and devices, no matter where you operate. Collected data<br />
                            It analyzes and visualizes according to rules and provides objective indicators that customers can utilize. From dozens of MB every day<br />
                            Up to tens of TB can be processed immediately regardless of scale, and through this, users can easily obtain the insights they need<br />
                            you can get In particular, this ability is powerful in the integrated security control system.
                        </div>
                        <div className="introBox">
                            <div className="imgScroll" >
                                <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800" >
                                    <img src="/images/splunk_02.png" />
                                </div>
                            </div>
                        </div>
                        <div className="introBox" style={{ paddingTop: "0" }}>
                            <div className="imgScroll">
                                <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                    <img src="/images/splunk_03.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* section 01 */}
                </div>
                {/* section 02 */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">The answer to IT security, Splunk</div>
                        </div>
                    </div>
                    <div className="introBox">
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_04.png" />
                            </div>
                        </div>
                    </div>
                    <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                        Splunk's power shines in the field of security. Data leakage can be prevented with an analysis-oriented SIEM,<br />
                        Prevents even unknown threats through user and object behavior analysis. Threat detection using machine learning<br />
                        Automation reduces discovery times with accurate, action-based alerts for quick review and resolution.
                    </div>
                </div>
                {/* section 02 */}
                {/* section 03 */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Splunk Security Solutions Journey</div>
                        </div>
                    </div>
                    <div className="introBox">
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_05.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 03 */}
                {/* section 04 */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Gaion, Splunk's top expert group.</div>
                        </div>
                        <div className="_typing-title center title-margin first" data-aos="fade-up" data-aos-duration="500">
                            We present the optimal solution with 15 years of experience and know-how.
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Splunk is used by dozens of customers, including national banks, financial institutions, national infrastructure public institutions, and IT systems for large corporations.<br />
                            We have successfully established an information security integrated control system.
                        </div>
                        <div className="_typing-title center title-margin" data-aos="fade-up" data-aos-duration="500">
                            Splunk Best Elite Partner, Gaion
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            We are joined by a number of experts with Splunk-recognized and technical certifications. In various fields<br />
                            Splunk experts with experience in successfully completing related projects such as integrated security control and big data,<br />
                            Artificial intelligence and security experts accurately identify customer needs and present and execute optimal solutions.
                        </div>
                        <div className="_typing-title center title-margin" data-aos="fade-up" data-aos-duration="500">
                            Completed technology, GAION
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            We developed and launched our own AI-based analysis platform, from data preprocessing to various big data analysis and machine learning.<br />
                            Based on this technology, we developed and applied a solution that detects anomalies in real time. Various domestic<br />
                            By designing and successfully building next-generation large-scale security control systems and architectures for large financial companies, we have established the highest level in Korea<br />
                            It proves that you have technical skills.
                        </div>
                    </div>
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                            <img src="/images/splunk_06.png" />
                        </div>
                    </div>
                </div>
                {/* section 04 */}
                {/* section 05 */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Splunk Core (Enterprise / Cloud)</div>
                        </div>
                        <div className="_typing-title center bold" data-aos="fade-up" data-aos-duration="500">
                            Collecting and analyzing scattered data in one place<br />
                            Splunk Core (Enterprise/Cloud)
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Flexible collection of any type of data at any time
                        </div>
                    </div>
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                            <img src="/images/splunk_07.png" />
                        </div>
                    </div>
                    <div className="_typing-content center" data-aos="fade-up" data-aos-duration="800">
                        As IT develops, the number of channels, types, and amounts of data can only increase exponentially.
                        doesn't exist. Splunk Core<br />
                        With an agent/agentless method, you can flexibly collect data from any channel at any time and use it within Splunk.<br /><br />
                        In addition, super-large data at the level of TB and PB can be collected in real time through flexible horizontal expansion.<br /><br />
                        <br />
                        We also provide installation and operation type optimization
                    </div>
                    <div className="introBox" style={{ paddingBottom: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_08.png" />
                            </div>
                        </div>
                    </div>
                    <div className="typing">
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Instantly derive insights with powerful machine learning analytics and visualizations and inform them in real time.
                        </div>
                    </div>
                    {/* <div className="introBox" style={{ paddingTop: "0" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_09.png" />
                            </div>
                        </div>
                    </div> */}
                    <div className="_typing-content center" data-aos="fade-up" data-aos-duration="800">
                        Splunk Core uses various algorithms and techniques as well as artificial intelligence analysis based on machine learning.
                        Provides rich analysis capabilities.<br /><br />
                        You can also build powerful visualization tools and user-friendly, flexible dashboards so you can see exactly what's happening and what it means<br />
                        It provides insights on how to respond in the future.<br /><br />
                        In addition, real-time alarms and report creation functions such as e-mail, SMS, and messengers are added to meet customer needs to provide seamless service.<br /><br />
                        With over 2,300 different apps, you can add as many features as you need.
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_10.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 05 */}
                {/* section 06 */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Splunk ES</div>
                        </div>
                        <div className="_typing-title center title-margin first" data-aos="fade-up" data-aos-duration="500">
                            The essence of next-generation SOP that solves the concerns of integrated security<br />Splunk ES
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            Analysis-driven to identify even unknown threats<br />Security Information & Event Management (SIEM)
                        </div>
                    </div>
                    <div className="imgScroll">
                        <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                            <img src="/images/splunk_11.png" />
                        </div>
                    </div>
                    <div className="_typing-content center" data-aos="fade-up" data-aos-duration="800">
                        Existing SIEMs collect alerts from each unit security product and create alerts, which are already known threats.
                        only<br />
                        They often work, leaving them vulnerable to new types of attacks.<br /><br />
                        Splunk ES is analysis-driven that collects and analyzes all security-related data.
                        With SIEM<br />
                        It even identifies ‘Unknwon Threats’. It also identifies and responds to threats that do not match existing security data.<br /><br />
                        Connecting to multiple security alerts, real-time in-depth analysis, and instant automated response all in one platform<br /><br />
                        Threat intelligence and correlation rules are established, based on which threats are detected, incidents are analyzed and handled, as well as a series of automatic responses<br />
                        Security monitoring tasks are quickly provided on a single platform. From threat detection to situation visualization, investigation case registration, in-depth analysis, <br />
                        You can minimize the damage by looking at the immediate response at a glance.
                    </div>
                    <div className="introBox" >
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_12.png" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 06 */}
                {/* section 07 */}
                <div className="wrap">
                    <div className="typing">
                        <div className="contentArea bar">
                            <div className="contentTitle" data-aos="fade-up" data-aos-duration="500">Gaion Splunk SOAR</div>
                        </div>
                        <div className="_typing-title center title-margin first" data-aos="fade-up" data-aos-duration="500">
                            It solves all problems of the integrated security control system.<br />
                            Gaion Security Automation Solution
                        </div>
                        <div className="_typing-text center" data-aos="fade-up" data-aos-duration="800">
                            GAION Splunk SOAR<br />(Security Orchestration, Automation and Response)
                        </div>
                    </div>
                    <div className="_typing-content center" data-aos="fade-up" data-aos-duration="800">
                        Why GAION Splunk SOAR is the Answer
                    </div>
                    <div className="introBox" style={{ paddingBottom: "30px" }}>
                        <div className="imgScroll">
                            <div className="wrap esonImage" data-aos="fade-up" data-aos-duration="800">
                                <img src="/images/splunk_12.png" />
                            </div>
                        </div>
                    </div>
                    <div className="_typing-content center" data-aos="fade-up" data-aos-duration="800">
                        Many problems arise when building and operating an integrated security control system. Sometimes a lot of unnecessary alerts come in, and security operations personnel<br />
                        Insufficiently, issues may be missed. It is difficult to keep up with the growing number of products and services, and the speed of the system is slow, leaving the golden time<br />
                        You may also miss it. In many cases, efficient operation is difficult due to the absence of repetitive tasks and processes. GAION Splunk SOAR <br />
                        We will solve all of these customer concerns. In particular, the existing security infrastructure SIEM (Security Information & Event Management) and <br />
                        By integrating OODA (Observe (Observe) → Orient (Situational Judgment) → Decide (Decision) → Act (Execution) Loop, the entire security task is implemented<br />
                        Automate. Through this, you can build a seamless integrated security control system with fewer resources, so you can feel more at ease<br />
                        We create a security system.
                    </div>
                    <div className="Link">
                        Contact : <span>sales@gaion.kr</span> (AI Solution Division, Sales Team)<br />
                        Tel :  <span>02.6956.5942</span>
                    </div>
                </div>
                {/* section 07 */}
            </div >
        </React.Fragment >
    );
}

export default Splunk;